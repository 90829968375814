<template>
  <div>
    Server side tree CRUD is under construction
  </div>
</template>

<script>

export default {}
</script>
